import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import PortfolioRecommendationStore from 'src/app/domain/store/portfolio-recommendation.store';
import PortfolioSnapshotStore from 'src/app/domain/store/portfolio-snapshot.store';
import {ApiClient} from 'src/app/infrastructure/http/api.client';
import {Modal} from 'src/app/infrastructure/service/modal.service';
import {TrackService} from 'src/app/infrastructure/service/track.service';
import MenuStatusStore from 'src/app/infrastructure/store/menuStatusStore';
import {Login, LoginWithAuthCode, Recover, Register} from 'src/app/domain/command/auth.command';
import {objKeysToSnakeCase, objToCamelCase} from 'src/app/domain/function/object.helper';
import {Auth, AuthGrantType} from 'src/app/domain/model/auth.model';
import AuthStore from 'src/app/domain/store/auth.store';
import CustomerPortfolioStore from 'src/app/domain/store/customer-portfolio.store';
import UserStore from 'src/app/domain/store/auth.store';
import UserApi from 'src/app/domain/service/api/user.api';
import CustomerStore from 'src/app/domain/store/customer/customer.store';

@Injectable({
  providedIn: 'root'
})
export default class AuthApi {
  private scheduleRefreshTokenInterval = -1;

  constructor(
    private api: ApiClient,
    private authStore: AuthStore,
    private userStore: UserStore,
    private customerPortfolioStore: CustomerPortfolioStore,
    private customerStore: CustomerStore,
    private recommendationsStore: PortfolioRecommendationStore,
    private snapshotStore: PortfolioSnapshotStore,
    private userApi: UserApi,
    private menuStatusStore: MenuStatusStore,
    private trackService: TrackService,
    private modal: Modal
  ) {
  }

  async recover(command: Recover) {
    await this.api.post('login/recover', command);
  }

  public me(): Observable<Auth> {
    return this.authStore.me$;
  }

  public async register(command: Register): Promise<void> {
    const snakeCommand: object = objKeysToSnakeCase(command);
    const auth: any = await this.api.post('register', snakeCommand);

    this.authStore.saveMe(auth);
    await this.userApi.fetchMe();

    this.scheduleRefreshToken(auth.expiresIn);
    await this.trackService.trackRegister(command);
  }

  public async login(command: Login): Promise<void> {
    const response: any = await this.api.post('login', command);
    const auth: Auth = objToCamelCase(response);

    this.authStore.saveMe(auth);

    const user = await this.userApi.waitFetchAndSync();
    this.scheduleRefreshToken(auth.expiresIn);
    await this.trackService.trackLogin(command.username);

    /* eslint-disable @typescript-eslint/naming-convention */
    const data = {
      first_name: user.firstName,
      surname: user.surname,
      email: user.email,
      username: user.username,
      created_at: user.registeredAt
    };

    await this.trackService.identifyUser(user.id, data);
  }

  public async loginWithAuthCode(command: LoginWithAuthCode): Promise<void> {
    const response: any = await this.api.post('login?grant_type='+ AuthGrantType.authorizationCode, command);
    const auth: Auth = objToCamelCase(response);

    this.authStore.saveMe(auth);
    await this.userApi.fetchMe();

    this.scheduleRefreshToken(auth.expiresIn);
  }

  public async refreshToken(): Promise<void> {
    const refreshToken = this.authStore.syncMe()?.refreshToken;
    const data: any = {};

    if (!refreshToken) {
      throw new Error('Error Refresh Token');
    }

    data.refresh_token = refreshToken;
    data.grant_type = AuthGrantType.refreshToken;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const params = {grant_type: AuthGrantType.refreshToken};

    try {
      const auth: Auth = await this.api.post('login', data, params);

      this.authStore.saveMe(auth);
      this.scheduleRefreshToken(auth.expiresIn);

    } catch (e) {
      this.clearRefreshTokenInterval();
      throw new Error('Error Refresh Token');
    }
  }

  public logout() {
    this.modal.dismiss();
    this.authStore.reset();
    this.snapshotStore.reset();
    this.userStore.reset();
    this.recommendationsStore.reset();
    this.customerStore.reset();
    this.customerPortfolioStore.reset();
    this.menuStatusStore.reset();
    this.clearRefreshTokenInterval();
  }

  private scheduleRefreshToken(expiresInSeconds: number): void {
    const expiresInMillis = Math.round(expiresInSeconds * 1000 * 0.8);

    if (this.scheduleRefreshTokenInterval !== -1) {
      return;
    }

    this.scheduleRefreshTokenInterval = window.setInterval(async () => {
      await this.refreshToken();
    }, expiresInMillis);
  }

  private clearRefreshTokenInterval() {
    clearInterval(this.scheduleRefreshTokenInterval);
  }
}
