import {Command} from './command.command';
import {uuid} from '../function/uuid.helper';

export interface Login extends Command {
  username: string;
  password: string;
}

export interface LoginWithAuthCode extends Command {
  code: string;
}

export interface Register extends Command {
  email: string;
  firstName: string;
  surname: string;
  invitation: string | null;
  marketingContact: boolean;
  termsAccepted: boolean;
}

export const emptyLogin = (): Login => ({
  commandId: uuid(),
  username: '',
  password: '',
});

export const createLoginWithAuthCode = (code: string): LoginWithAuthCode => ({
  commandId: uuid(),
  code,
});

export const emptyRegister = (): Register => ({
  commandId: uuid(),
  email: '',
  firstName: '',
  surname: '',
  invitation: null,
  marketingContact: false,
  termsAccepted: false,
});

export interface Recover extends Command {
  email: string;
}

export const emptyRecover = (): Recover => ({
  commandId: uuid(),
  email: '',
});

