import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class OperationalBlockRouteResolver {
  private readonly rootUrl: string = '/operational-block';

  public resolveSummaryRoute(): string {
    return `${this.rootUrl}/summary`;
  }

  public resolveKycRoute(): string {
    return `${this.rootUrl}/kyc`;
  }

  public resolveCustomerData(): string {
    return `${this.rootUrl}/customer-data`;
  }

  public resolveKycResults(): string {
    return `${this.rootUrl}/show-results`;
  }

  public resolveKycAcceptContract(): string {
    return `${this.rootUrl}/accept-contract`;
  }
}
