import {Component, Input} from '@angular/core';
import {AsyncFn} from 'src/app/domain/function/type.helper';
import {Router} from 'src/app/infrastructure/router/router';
import {WebComponent} from 'src/app/ui/webapp/component/web.component';

type LinkType = 'none' | 'underline' | 'button';
type TextColor =
    'light' |
    'dark' |
    'inherit' |
    'primary' |
    'gray' |
    'error';

@Component({
  selector: 'app-link',
  template: `
    <span (click)="goTo($event)"
          class="cursor-pointer"
          [ngClass]="{
         'text-primary': color === 'primary' && this.enabled,
         'text-gray-400': !this.enabled,
         'text-white': color === 'light',
         'text-error': color === 'error',
         'underline decoration-solid': type === 'underline' && this.enabled,
         }">
      <ng-content></ng-content>
    </span>
  `
})
export class LinkComponent extends WebComponent {
  @Input() route: string;
  @Input() preventFn?: AsyncFn<boolean>;
  @Input() fragment: string;
  @Input() params: object = {};
  @Input() type: LinkType = 'underline';
  @Input() color: TextColor = 'inherit';
  @Input() enabled = true;

  constructor(
    private router: Router
  ) {
    super();
  }

  async goTo($event: MouseEvent): Promise<void> {
    $event.preventDefault();
    if (!this.route) {
      return;
    }

    if (this.preventFn && await this.preventFn()) {
      return;
    }

    if (!this.routeIsInternal()) {
      window.open(
        this.routeAndParams(),
        this.route.startsWith('http')
          ? '_blank'
          : '_self'
      );
      return;
    }

    this.router.navigate([this.route], {
      queryParams: this.params,
      fragment: this.fragment,
    });
  }

  private routeIsInternal(): boolean {
    return this.route.startsWith('/');
  }

  private routeAndParams(): string {
    const route = new URL(this.route);

    for (const [key, value] of Object.entries(this.params)) {
      route.searchParams.append(key, value);
    }

    return route.href;
  }
}
