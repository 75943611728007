import {ScrollingModule} from '@angular/cdk/scrolling';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {IonicModule} from '@ionic/angular';
import {InfrastructureModule} from 'src/app/infrastructure/infrastructure.module';
import {ChangeButtonComponent} from 'src/app/ui/shared/component/button/change-button.component';
import {SuccessButtonComponent} from 'src/app/ui/shared/component/button/success-button.component';
import {CopyButtonComponent} from 'src/app/ui/shared/component/button/copy-button.component';
import {EditButtonComponent} from 'src/app/ui/shared/component/button/edit-button.component';
import {FilledButtonComponent} from 'src/app/ui/shared/component/button/filled-button.component';
import {CountdownTimerComponent} from 'src/app/ui/shared/component/countdown-timer/countdown-timer.component';
import {DocumentComponent} from 'src/app/ui/shared/component/docs/document.component';
import {FormInputDecoratorComponent} from 'src/app/ui/shared/component/input/form-input-decorator.component';
import {RadioButtonComponent} from 'src/app/ui/shared/component/input/radio-button.component';
import {ShowHidePasswordComponent} from 'src/app/ui/shared/component/input/show-hide-password/show-hide-password.component';
import {TokenInputComponent} from 'src/app/ui/shared/component/input/token-input.component';
import {UploadMultipleFilesComponent} from 'src/app/ui/shared/component/input/upload-multiple-files.component';
import {NoticeComponent} from 'src/app/ui/shared/component/notice/notice.component';
import {ProgressBarComponent} from 'src/app/ui/shared/component/progress-bar/progress-bar';
import {AhorrobotUnitLinkedProductComponent} from 'src/app/ui/webapp/component/logo/product/ahorrobot-unit-linked-product.component';
import {EvoUnitLinkedProductComponent} from 'src/app/ui/webapp/component/logo/product/evo-unit-linked-product.component';
import {InversisInvestmentProductComponent} from 'src/app/ui/webapp/component/logo/product/inversis-investment-product.component';
import {UnitLinkedProductForChildComponent} from 'src/app/ui/webapp/component/logo/product/unit-linked-product-for-child.component';
import {EvoLogoComponent} from '../../webapp/component/logo/evo-logo.component';
import {FinizensLogoComponent} from '../../webapp/component/logo/finizens-logo.component';
import {PensionPlanProductComponent} from '../../webapp/component/logo/product/pension-plan-product.component';
import {ProductLogoComponent} from '../../webapp/component/logo/product/product-logo.component';
import {UnitLinkedProductComponent} from '../../webapp/component/logo/product/unit-linked-product.component';
import {MoneyPipe} from '../pipe/money.pipe';
import {NumberPipe} from '../pipe/number.pipe';
import {PercentagePipe} from '../pipe/percentage.pipe';
import {PluralizePipe} from '../pipe/pluralize.pipe';
import {SafePipe} from '../pipe/safe.pipe';
import {TranslationPipe} from '../pipe/translation.pipe';
import {AccordionComponent} from './accordion/accordion.component';
import {ContentBoxComponent} from './box/content-box.component';
import {WarningBoxComponent} from './box/warning-box.component';
import {ButtonComponent} from './button/button.component';
import {CustodianLogoComponent} from './custodian-logo/custodian-logo.component';
import {PreviewModalPage} from './docs/modal/preview-modal.page';
import {TemplateDocumentViewerComponent} from './docs/template-document-viewer.component';
import {ErrorBoxComponent} from './error/error-box.component';
import {PlaneIconComponent} from './icon/plane-icon.component';
import {CheckboxComponent} from './input/checkbox.component';
import {DateTimeComponent} from './input/datetime.component';
import {DropdownComponent} from './input/dropdown.component';
import {InputComponent} from './input/input.component';
import {InvestmentFundsSearchComponent} from './input/investment-funds-search.component';
import {PensionPlanFundsSearchComponent} from './input/pension-plan-funds-search.component';
import {RangeComponent} from './input/range.component';
import {SearchComponent} from './input/search/search.component';
import {ToggleComponent} from './input/toggle.component';
import {TokenCharComponent} from './input/token-char.component';
import {UploadComponent} from './input/upload.component';
import {LinkComponent} from './link/link.component';
import {LoaderOverplayComponent} from './loader-overplay/loader-overplay.component';
import {LogoComponent} from './logo/logo.component';
import {CaserUlLogoComponent} from './logo/partner/caser-ul-logo.component';
import {CecabankLogoComponent} from './logo/partner/cecabank-logo.component';
import {InversisLogoComponent} from './logo/partner/inversis-logo.component';
import {PartnerLogoComponent} from './partner-logo/partner-logo.component';
import {PoweredByComponent} from './powered-by/powered-by.component';
import {ProductIconComponent} from './product/product-icon.component';
import {TabComponent} from './tab/tab-bar.component';

@NgModule({
  declarations: [
    FormInputDecoratorComponent,
    ButtonComponent,
    SuccessButtonComponent,
    FilledButtonComponent,
    InputComponent,
    TokenInputComponent,
    SearchComponent,
    InvestmentFundsSearchComponent,
    PensionPlanFundsSearchComponent,
    LogoComponent,
    LinkComponent,
    UploadComponent,
    UploadMultipleFilesComponent,
    CheckboxComponent,
    ToggleComponent,
    TokenCharComponent,
    PoweredByComponent,
    ProductIconComponent,
    ErrorBoxComponent,
    WarningBoxComponent,
    ContentBoxComponent,
    RangeComponent,
    TranslationPipe,
    MoneyPipe,
    PercentagePipe,
    NumberPipe,
    PluralizePipe,
    SafePipe,
    FinizensLogoComponent,
    EvoLogoComponent,
    CaserUlLogoComponent,
    PensionPlanProductComponent,
    InversisInvestmentProductComponent,
    UnitLinkedProductComponent,
    AhorrobotUnitLinkedProductComponent,
    EvoUnitLinkedProductComponent,
    UnitLinkedProductForChildComponent,
    ProductLogoComponent,
    DateTimeComponent,
    DropdownComponent,
    PlaneIconComponent,
    LoaderOverplayComponent,
    AccordionComponent,
    CustodianLogoComponent,
    CecabankLogoComponent,
    InversisLogoComponent,
    PartnerLogoComponent,
    TemplateDocumentViewerComponent,
    PreviewModalPage,
    TabComponent,
    DocumentComponent,
    EditButtonComponent,
    CopyButtonComponent,
    ChangeButtonComponent,
    NoticeComponent,
    RadioButtonComponent,
    CountdownTimerComponent,
    ProgressBarComponent,
    ShowHidePasswordComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    InfrastructureModule,
    ScrollingModule],
  exports: [
    InfrastructureModule,
    FormInputDecoratorComponent,
    ButtonComponent,
    SuccessButtonComponent,
    FilledButtonComponent,
    InputComponent,
    TokenInputComponent,
    SearchComponent,
    InvestmentFundsSearchComponent,
    PensionPlanFundsSearchComponent,
    LogoComponent,
    LinkComponent,
    UploadComponent,
    UploadMultipleFilesComponent,
    CheckboxComponent,
    ToggleComponent,
    TokenCharComponent,
    PoweredByComponent,
    ProductIconComponent,
    ErrorBoxComponent,
    WarningBoxComponent,
    ContentBoxComponent,
    RangeComponent,
    DateTimeComponent,
    DropdownComponent,
    TranslationPipe,
    MoneyPipe,
    PercentagePipe,
    NumberPipe,
    PluralizePipe,
    SafePipe,
    FinizensLogoComponent,
    CaserUlLogoComponent,
    PensionPlanProductComponent,
    InversisInvestmentProductComponent,
    UnitLinkedProductComponent,
    AhorrobotUnitLinkedProductComponent,
    EvoUnitLinkedProductComponent,
    UnitLinkedProductForChildComponent,
    ProductLogoComponent,
    PlaneIconComponent,
    LoaderOverplayComponent,
    AccordionComponent,
    CustodianLogoComponent,
    CecabankLogoComponent,
    InversisLogoComponent,
    PartnerLogoComponent,
    TemplateDocumentViewerComponent,
    TabComponent,
    DocumentComponent,
    EditButtonComponent,
    ChangeButtonComponent,
    CopyButtonComponent,
    RadioButtonComponent,
    NoticeComponent,
    CountdownTimerComponent,
    ProgressBarComponent,
  ]
})
export class SharedComponentModule {
}
